.experience-container {
  margin-left: 80px;
  min-height: 100vh;
  padding: 2rem;
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
}

.experience-content {
  background: white;
  border-radius: 20px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 1200px;
  padding: 3rem;
  margin: 2rem;
}

.intro-section {
  text-align: center;
  margin-bottom: 3rem;
  color: #666;
  font-size: 1.1rem;
  line-height: 1.6;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.section-header {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 2rem;
}

.header-icon {
  font-size: 1.8rem;
  color: #60A5FA;
}

.section-header h2 {
  font-size: 1.8rem;
  color: #2d2d2d;
  margin: 0;
}

.skills-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
  margin-bottom: 2rem;
}

.skill-card {
  background: white;
  border-radius: 12px;
  padding: 1.5rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease;
}

.skill-card:hover {
  transform: translateY(-5px);
}

.skill-icon {
  font-size: 2rem;
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f0f9ff;
  border-radius: 12px;
}

.skill-details {
  flex: 1;
}

.skill-details h3 {
  margin: 0;
  font-size: 1.1rem;
  color: #2d2d2d;
}

.skill-level {
  display: inline-block;
  padding: 0.3rem 0.8rem;
  border-radius: 12px;
  font-size: 0.85rem;
  font-weight: 500;
  margin-top: 0.5rem;
}

.skill-level.advanced {
  background-color: #dcfce7;
  color: #166534;
}

.skill-level.intermediate {
  background-color: #fff7ed;
  color: #9a3412;
}

.skill-level.beginner {
  background-color: #fef2f2;
  color: #991b1b;
}

.progress-bar {
  height: 8px;
  background-color: #e5e7eb;
  border-radius: 4px;
  overflow: hidden;
}

.progress-fill {
  height: 100%;
  background-color: #60A5FA;
  border-radius: 4px;
  transition: width 1s ease-in-out;
}

.divider {
  height: 1px;
  background-color: #e5e7eb;
  margin: 3rem 0;
}

.career-section {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3rem;
}

.timeline {
  border-left: 2px solid #60A5FA;
  padding-left: 2rem;
  margin-left: 1rem;
}

.timeline-item {
  position: relative;
  padding-bottom: 2rem;
}

.timeline-item::before {
  content: '';
  position: absolute;
  left: -2.6rem;
  top: 0.5rem;
  width: 1rem;
  height: 1rem;
  background-color: #60A5FA;
  border-radius: 50%;
}

.timeline-item h3 {
  color: #2d2d2d;
  margin-bottom: 0.5rem;
}

.timeline-date {
  color: #60A5FA;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.timeline-item p {
  color: #4b5563;
  margin-bottom: 0.5rem;
}

.timeline-item ul {
  color: #4b5563;
  padding-left: 1.2rem;
}

.timeline-item li {
  margin-bottom: 0.5rem;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .experience-content {
    padding: 2rem;
    margin: 1rem;
  }

  .career-section {
    grid-template-columns: 1fr;
  }

  .section-header {
    flex-direction: column;
    text-align: center;
  }

  .experience-container {
    margin-left: 0;  /* Remove left margin on mobile */
    margin-bottom: 60px;  /* Space for bottom nav */
  }
}

/* Handle landscape orientation on mobile */
@media (max-height: 500px) and (orientation: landscape) {
  .experience-container {
    margin-left: 60px;  /* Adjust for narrower sidebar in landscape */
  }
}

/* Handle tablets */
@media (min-width: 769px) and (max-width: 1024px) {
  .experience-container {
    margin-left: 70px;  /* Match tablet nav width */
  }
}

.resume-button {
  margin-top: 2rem;
  padding: 0.8rem 1.5rem;
  background-color: #60A5FA;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.resume-button:hover {
  background-color: #3b82f6;
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.resume-button:active {
  transform: translateY(0);
}

.resume-button svg {
  font-size: 1.1rem;
}
