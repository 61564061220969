.contact-container {
  margin-left: 80px;
  min-height: 100vh;
  padding: 2rem;
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact-card {
  background: white;
  border-radius: 20px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  padding: clamp(1.5rem, 5vw, 3rem);
  text-align: center;
  max-width: 600px;
  width: 90%;
  margin: 1rem;
}

.contact-icon {
  font-size: clamp(2rem, 4vw, 3rem);
  color: #60A5FA;
  margin-bottom: clamp(1rem, 3vw, 1.5rem);
  animation: float 3s ease-in-out infinite;
}

.contact-card h1 {
  font-size: clamp(1.8rem, 4vw, 2.5rem);
  color: #1a1a1a;
  margin-bottom: clamp(1rem, 3vw, 1.5rem);
}

.contact-card p {
  color: #666;
  font-size: clamp(1rem, 2vw, 1.1rem);
  line-height: 1.6;
  margin-bottom: clamp(1.5rem, 4vw, 2rem);
  padding: 0 clamp(0.5rem, 2vw, 1rem);
}

.email-button {
  background-color: #60A5FA;
  color: white;
  border: none;
  border-radius: 12px;
  padding: clamp(0.8rem, 2vw, 1rem) clamp(1.5rem, 3vw, 2rem);
  font-size: clamp(0.9rem, 2vw, 1.1rem);
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  display: inline-flex;
  align-items: center;
  gap: 0.8rem;
  white-space: nowrap;
}

.email-button:hover {
  background-color: #3b82f6;
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(59, 130, 246, 0.3);
}

@keyframes float {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .contact-container {
    margin-left: 0;
    padding: 1rem;
  }
}

/* Extra small devices */
@media (max-width: 480px) {
  .contact-card {
    width: 95%;
    margin: 0.5rem;
  }
  
  .email-button {
    width: 100%;
    justify-content: center;
  }
}

/* Handle landscape orientation on mobile */
@media (max-height: 500px) and (orientation: landscape) {
  .contact-container {
    padding: 1rem;
  }
  
  .contact-card {
    padding: 1rem;
  }
  
  .contact-icon {
    margin-bottom: 0.5rem;
  }
  
  .contact-card h1 {
    margin-bottom: 0.5rem;
  }
  
  .contact-card p {
    margin-bottom: 1rem;
  }
}
