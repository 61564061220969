.home-container {
  margin-left: 80px;
  min-height: 100vh;
  padding: 2rem;
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .home-container {
    margin-left: 0;  /* Remove left margin on mobile */
    margin-bottom: 60px;  /* Add bottom margin for the mobile nav */
  }
}

/* Handle landscape orientation on mobile */
@media (max-height: 500px) and (orientation: landscape) {
  .home-container {
    margin-left: 60px;  /* Adjust for narrower sidebar in landscape */
  }
}

/* Handle tablets */
@media (min-width: 769px) and (max-width: 1024px) {
  .home-container {
    margin-left: 70px;  /* Match tablet nav width */
  }
}

.about-card {
  background: white;
  border-radius: 20px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 1200px;
  padding: 3rem;
  margin: 2rem;
}

.profile-section h1 {
  font-size: 2.5rem;
  color: #1a1a1a;
  margin-bottom: 1rem;
}

.personal-info {
  display: flex;
  gap: 2rem;
  color: #666;
  margin-bottom: 2rem;
}

.personal-info span {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.bio {
  font-size: 1.1rem;
  line-height: 1.6;
  color: #444;
  max-width: 800px;
}

.divider {
  height: 1px;
  background: linear-gradient(to right, transparent, #ddd, transparent);
  margin: 3rem 0;
}

.specialties-section h2 {
  font-size: 2rem;
  color: #1a1a1a;
  margin-bottom: 2rem;
  text-align: center;
}

.specialties-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
}

.specialty-card {
  background: #f8f9fa;
  border-radius: 15px;
  padding: 2rem;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.specialty-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
}

.specialty-icon {
  font-size: 2.5rem;
  color: #60A5FA;
  margin-bottom: 1rem;
}

.specialty-card h3 {
  color: #2d2d2d;
  margin-bottom: 1rem;
  font-size: 1.3rem;
}

.specialty-card p {
  color: #666;
  line-height: 1.5;
  font-size: 1rem;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .about-card {
    padding: 2rem;
    margin: 1rem;
  }

  .personal-info {
    flex-direction: column;
    gap: 1rem;
  }

  .specialties-grid {
    grid-template-columns: 1fr;
  }
}

.hobbies-section {
  padding: 2rem 0;
}

.hobbies-section h2 {
  font-size: 2rem;
  color: #1a1a1a;
  margin-bottom: 2rem;
  text-align: center;
}

.hobbies-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
}

.hobby-card {
  background: #f8f9fa;
  border-radius: 15px;
  padding: 1.5rem;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}


.hobby-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.hobby-icon {
  font-size: 2rem;
  color: #60A5FA;
  margin-bottom: 1rem;
  text-align: center;
}

.hobby-card h3 {
  font-size: 1.3rem;
  color: #2d2d2d;
  margin-bottom: 0.8rem;
  text-align: center;
}

.hobby-card p {
  color: #666;
  line-height: 1.5;
  text-align: center;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .hobbies-grid {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
  
  .hobby-card {
    padding: 1.2rem;
  }
}

/* Add to your existing CSS */
.specialty-card, .hobby-card {
  will-change: transform;
  transform-origin: center center;
}

/* Optional: Add this if you want a smoother shadow transition */
.specialty-card, .hobby-card {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}

.specialty-card:hover, .hobby-card:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
}

.linkedin-link {
  color: #0077b5;  /* LinkedIn blue */
  text-decoration: none;
  transition: color 0.3s ease;
}

.linkedin-link:hover {
  color: #005582;  /* Darker shade for hover */
}
